.hero {
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Ensures the text and image containers have the same height */
  justify-content: space-between;
  width: 100%;
  margin-top: 180px;
  margin-bottom: 180px;

  .encrypted-photo {
    border-radius: '45px';
    background-color: '#725e93';
    width: '75%';
    top: 0;
    left: 0;
  }

  .jumbo-text {
    font-size: 3.5rem;
    line-height: 1.5;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 2rem;
    height: 180px; /* Shrink the text height slightly */
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .birb {
    width: 10rem;
    height: auto;
    position: absolute;
    bottom: 0;
    left: -10rem;
    transform: scaleX(-1);
  }

  .mock-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .mock {
    width: 250px; /* Size for desktop */
    transition: margin-left 0.3s ease-in-out; /* Smooth transition for overlap */
  }

  /* Desktop view with increasing overlap as screen width decreases */
  @media (max-width: 1200px) {
    .mock {
      margin-left: -16%; /* Increase overlap for smaller desktop screens */
    }
  }

  @media (max-width: 992px) {
    .mock {
      margin-left: -18%; /* Increase overlap for smaller desktop screens */
    }
  }

  /* Mobile view */
  @media (max-width: 768px) {

    flex-direction: column-reverse;
    align-items: center;

    .jumbo-text {
      font-size: 2rem;
    }

    .text-container {
      align-items: center;
      height: auto;
      text-align: center;
      margin-right: 0;
      padding: 0 20px;
    }

    .image-container {
      margin-bottom: 2rem;
      width: 100%; /* Make sure the container fits the screen */
    }

    .mock {
      width: calc(38vw - 10px); /* Ensure images fit within container */
      margin-left: -20%;
    }
  }
}

.google-play-link {
  /* Remove default padding if you don’t want a big button wrapper */
  padding: 0;
  /* Optional adjustments, if desired */
  text-decoration: none;
  display: inline-block;
}

.google-play-badge-top {
  width: 40%;
  height: auto;
}

.google-play-badge {
  width: 60%;
  height: auto;
}


/* Tablet view */
@media (max-width: 768px) {

  .google-play-badge-top {
    width: 60%;
    height: auto;
  }

}

// Mobile View
@media (max-width: 435px) {

  .google-play-badge-top {
    width: 80%;
    height: auto;
  }

}
