.terms-list {
	list-style-type: disc;
	padding-left: 20px;
	margin-bottom: 10px;
}

.terms-sublist {
	list-style-type: circle;
	padding-left: 20px;
	margin-bottom: 10px;
}

.terms-list-item {
	margin-bottom: 10px;
}
