h1 {
	font-size: 24px;
}

ol {
	font-size: 18px;
	line-height: 1.6;
}

ol li {
	margin-bottom: 15px;
}

.privacy-link {
	text-decoration: none;
	font-size: 1rem;
	font-weight: bold;
	height: 4vh;
	align-content: center;
}
