#resetPasswordForm {
	margin: 0 auto;
	max-width: 550px;
	margin-top: 100px;

	input[type="password"] {
		margin-bottom: 20px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	label {
		margin-bottom: 20px;
	}

	button:disabled {
		background-color: #DEE1E6FF;
		cursor: not-allowed;
	}
}